@import "../../node_modules/bulma/sass/utilities/mixins.sass";

@mixin flex-column-fullheight {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body#section-news {
  @include tablet {
    article {
      .card {
        @include flex-column-fullheight;

        .card-content {
          @include flex-column-fullheight;

          .content {
            @include flex-column-fullheight;

            p:last-of-type {
              margin-top: auto;
            }
          }
        }
      }
    }
  }
}
