// API
$apiImagesLoadURL: "https://api.mrftyres.es/images/download";

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,400&display=swap");
$bodyFont: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Colors
$accentColor: #e9000a;
$darkTextColor: #121212;
$lightTextColor: #fafafa;
$darkBackgroundColor: $darkTextColor;
