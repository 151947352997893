#products {
  position: relative;

  h2 {
    display: inline-block;
    min-width: 70%;
  }

  img {
    filter: grayscale(100%);
    transition: filter 500ms linear;
    width: 100%;

    &:hover {
      filter: none;
    }
  }
}
