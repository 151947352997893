@import "misc/variables";

// Bulma
$family-sans-serif: $bodyFont;
$primary: $accentColor;
$breadcrumb-item-padding-vertical: 0.75em;
$breadcrumb-item-color: $darkTextColor;
$breadcrumb-item-hover-color: $accentColor;
$breadcrumb-item-active-color: #9e9e9e;
@import "bulma";

// Bulma Carousel
@import "index";

// Font Awesome Free
$fa-font-path: "../fonts/webfonts";
@import "fontawesome";
@import "regular";
@import "solid";
@import "brands";

// Custom
@import "main";
@import "sections/home";
@import "sections/motorsport-tyres";
@import "sections/tyre-tips";
@import "sections/category-details";
@import "sections/news";
@import "sections/post";
@import "partials/tyre-overview";

.is-relative {
  margin-bottom: 3rem;
}