body#section-tyre-tips {
  td {
    &.gray-900 {
      background-color: #212121;
    }
    &.gray-800 {
      background-color: #424242;
    }
    &.gray-700 {
      background-color: #616161;
    }

    &.purple-100 {
      background-color: #d1c4e9;
    }
    &.purple-200 {
      background-color: #b39ddb;
    }
    &.purple-300 {
      background-color: #9575cd;
    }
    &.purple-700 {
      background-color: #512da8;
    }

    &.orange-50 {
      background-color: #fff3e0;
    }
    &.orange-100 {
      background-color: #ffe0b2;
    }
    &.orange-200 {
      background-color: #ffcc80;
    }
    &.orange-300 {
      background-color: #ffb74d;
    }
    &.orange-400 {
      background-color: #ffa726;
    }
    &.orange-500 {
      background-color: #ff9800;
    }
    &.orange-600 {
      background-color: #fb8c00;
    }
    &.orange-700 {
      background-color: #f57c00;
    }
    &.orange-800 {
      background-color: #ef6c00;
    }
    &.orange-900 {
      background-color: #e65100;
    }
  }
}
