@import "../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../misc/variables";

body#section-home {
  main {
    padding: 0;
    background-color: #fff;

    #main-carousel {
      img {
        width: 100%;
      }

      .slider-navigation-next {
        right: 1em;
      }

      .slider-navigation-previous {
        left: 1em;
      }
    }

    @import "../partials/products";

    #products {
      @include desktop {
        top: -3em;
      }

      @include widescreen {
        top: -4em;
      }

      @include fullhd {
        top: -5em;
      }
    }
  }
}
