.slider {
	position: relative;
	width: 100%;
 }	// overflow: hidden
.slider-container {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	min-height: 100%;
	&.is-vertical {
		flex-direction: column; }
	.slider-item {
		flex: none;
		.image {
			&.is-covered {
				img {
					object-fit: cover;
					object-position: center center;
					height: 100%;
					width: 100%; } } }
		// Responsive embedded objects
		.video-container {
			height: 0;
			padding-bottom: 0;
			padding-top: 56.25%; // ratio 16:9
			margin: 0;
			position: relative;
			&.is-1by1,
			&.is-square {
				padding-top: 100%; }
			&.is-4by3 {
				padding-top: 75%; }
			&.is-21by9 {
				padding-top: 42.857143%; }
			& iframe,
			& object,
			& embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important; } } } }


.slider-navigation-previous,
.slider-navigation-next {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 42px;
	height: 42px;
	background: white center center no-repeat;
	background-size: 20px 20px;
	border: 1px solid white;
	border-radius: 25091983px;
	box-shadow: 0 2px 5px #3232321a;
	top: 50%;
	margin-top: -20px;
	left: 0px;
	cursor: pointer;
	transition: transform .3s, opacity .3s;
	&:hover {
		transform: scale(1.2); }
	&.is-hidden {
		display: none;
		opacity: 0; }
	svg {
		width: 25%; } }
.slider-navigation-next {
	left: auto;
	right: 0px;
	background: white center center no-repeat;
	background-size: 20px 20px; }

.slider-pagination {
	display: none;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: .5rem 1rem;
	text-align: center;
	.slider-page {
		background: white;
		width: 10px;
		height: 10px;
		border-radius: 25091983px;
		display: inline-block;
		margin: 0 3px;
		box-shadow: 0 2px 5px #3232321a;
		transition: transform .3s;
		cursor: pointer;
		&.is-active,
		&:hover {
			transform: scale(1.4); } }

	@media screen and (min-width: 800px) {
		display: flex; } }


// Hero Carousel
@mixin hero-carousel {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: auto;
	border: none;
	margin: auto;
	padding: 0;
	z-index: 0; }

.hero {
	&.has-carousel {
		position: relative;
		+ .hero-body,
		+ .hero-head,
		+ .hero-footer {
			z-index: 10;
			overflow: hidden; }
		.hero-carousel {
			@include hero-carousel;
			.slider {
				width: 100%;
				max-width: 100%;
				overflow: hidden;
				height: 100% !important;
				max-height: 100%;
				z-index: 0;
				.has-background {
					max-height: 100%;
					.is-background {
						object-fit: cover;
						object-position: center center;
						height: 100%;
						width: 100%; } } } }
		.hero-body {
			margin: 0 3rem;
			z-index: 10; } } }
