@import "../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../misc/variables";

.tyre-overview {
  @include mobile {
    > .is-half-mobile {
      margin: 0 auto;
    }
  }

  .description {
    p {
      margin-top: 1em;
    }
    @include desktop {
      position: relative;
      color: $lightTextColor;
      overflow: hidden;
      margin: 1em 0;
      &::before {
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $accentColor;
        transform-origin: 100% 100%;
        transform: skewX(-25deg);
      }

      p {
        margin-top: 0;
        padding: 1em 1em 1em 4em;
        position: relative;
      }
    }
  }
}
