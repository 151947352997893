@import "../../node_modules/bulma/sass/utilities/mixins.sass";
@import "../misc/variables";
@import "../misc/mixins";

#section-category-details {
  .lateral-img {
    &-mobile {
      display: none;

      @include touch {
        display: block;
        max-width: 40%;
        margin: {
          left: 2em;
          bottom: 2em;
        }
      }

      @include mobile {
        max-width: 50%;
      }

      @include mobile-small {
        max-width: 100%;
      }
    }

    > div {
      flex: 1;
      background: {
        size: cover;
        position: center;
        repeat: no-repeat;
      }
    }

    @include touch {
      display: none !important;

      > div {
        background-image: none !important;
      }
    }
  }
}
