@import "../../node_modules/bulma/sass/utilities/mixins.sass";

body#section-post {
  article {
    blockquote {
      background-color: transparent;
      border-left: 0.3rem solid #e9000a;
      padding: 1rem 1.25rem;
    }

    figure {
      @include tablet {
        float: right;
        max-width: 50%;
        margin: 0;
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      @include tablet-only {
        max-width: 60%;
      }
    }

    .facebook-16by9 {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      margin-bottom: 1em;

      iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }

    img{
      padding-bottom: 2rem;
    }

    .text-center{
      text-align: center;
    }
  }
}
