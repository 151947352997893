@import "misc/variables";
@import "misc/mixins";
@import "../node_modules/bulma/sass/utilities/mixins.sass";

@keyframes shine {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

html {
  &.no-js {
    .lazy {
      display: none;
    }
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    /* START GLOBAL ELEMENTS */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.mrf-title {
        color: $lightTextColor;
        background-color: $accentColor;
        padding: 0.25em;
        text-transform: uppercase;
        &::before {
          content: "/ ";
        }

        &.dark {
          background-color: $darkBackgroundColor;
        }
      }

      &.mrf-title-light {
        color: $accentColor;
        padding: 0.25em 0;
      }
    }

    p {
      margin-bottom: 0.4em;
    }

    article {
      section {
        margin-top: 1em;
        margin-bottom: 1.5em;
      }
    }

    main {
      background-color: #eee;
      padding: 3em 0;
      @include touch {
        padding: 0;
      }

      article {
        text-align: justify;

        > img {
          &:first-of-type {
            margin-bottom: 1em;
          }
        }
      }
    }

    address {
      margin-top: 0.75em;
    }

    table {
      &.is-nowrap {
        td {
          white-space: nowrap;
        }
      }

      caption {
        padding-top: 1em;
        caption-side: bottom;
        text-align: right;
      }
    }

    .mrf-link {
      color: $accentColor;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 1px;
        transition: all 0.4s ease-in-out;
        opacity: 0;
        background-color: $accentColor;
      }
      &::before {
        left: 50%;
      }
      &::after {
        right: 50%;
      }
      &:hover {
        &::before,
        &::after {
          width: 50%;
          opacity: 1;
        }
      }
    }

    .is-inherit {
      color: inherit;
    }

    .lazy {
      animation-name: shine;
      animation-duration: 2.4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      background: linear-gradient(to right, #ddd 0%, #fafafa 10%, #ddd 20%);
      background-size: 50%;
    }

    .column {
      &.is-full-mobile-mrf {
        @include mobile-small {
          flex: none;
          width: 100%;
        }
      }
    }

    .breadcrumb {
      * {
        text-transform: uppercase !important;
        font-size: 0.75rem;
      }

      @include touch {
        margin: 0 1.5rem;
        overflow: hidden;

        ul {
          flex-wrap: nowrap;
          li {
            &:not(:first-of-type) {
              &:not(:last-of-type) {
                display: none;
              }
            }

            &:nth-last-of-type(2) {
              &:not(:first-of-type) {
                display: flex;
                a {
                  &::before {
                    content: "... <";
                  }

                  span {
                    display: none;
                  }
                }
              }
            }

            &.is-active {
              flex: 1;
              /* (100% - Width de HOME - width de ... <) */
              max-width: calc(100% - 39px - 40px);
              a {
                display: inline;
                overflow: hidden;
                text-overflow: ellipsis;
                direction: rtl;
              }
            }
          }
        }
      }
    }

    .has-link-propagation {
      cursor: pointer;
    }

    /* Cookieconsent: en pequeño ocupa el header */
    .cc-revoke {
      @include mobile {
        display: none !important;
      }
    }

    @import "misc/spacing";
    /* END GLOBAL ELEMENTS */

    /* START PAGE HEADER */
    header#main-header {
      #top-nav {
        background-color: $accentColor;
        color: $lightTextColor;

        a {
          color: inherit;
        }
      }

      nav.navbar {
        .navbar-brand {
          > .navbar-item {
            strong {
              margin-left: 0.5em;
            }

            @include desktop {
              position: absolute;
              border-bottom-left-radius: 1em;
              border-bottom-right-radius: 1em;
              background-color: $lightTextColor;
              max-width: 8em;
              box-shadow: 0 0.1em 0.1em 0 $darkBackgroundColor;

              img {
                max-height: 100%;
              }

              strong {
                display: none;
              }
            }
          }
        }

        .navbar-item {
          &:hover {
            color: $accentColor;
          }
        }
      }

      #claim {
        background-color: $darkBackgroundColor;
        color: $lightTextColor;
        padding: 0.25em;

        * {
          color: inherit;
        }
      }
    }
    /* END PAGE HEADER */

    /* START PAGE PREFOOTER */
    section#prefooter {
      padding: 2em 0;
    }
    /* END PAGE PREFOOTER */

    /* START PAGE FOOTER */
    footer#main-footer {
      margin-top: auto;
      border-top: solid 10px $accentColor;
      background-color: $darkBackgroundColor;
      color: $lightTextColor;

      .footer {
        background-color: inherit;
        color: inherit;
        border-bottom: solid 1px #212223;
        padding: 2em 1.5em 1em;

        address {
          margin-top: 0;
        }

        .logo-container {
          img {
            filter: brightness(0) invert(1);
            max-width: 200px;
          }
        }

        .socials {
          font-size: 1.5em;

          p {
            margin-bottom: 0;
          }
        }
      }

      .copy {
        padding: 1em 0;
        margin-bottom: 0;
      }
    }
    /* END PAGE FOOTER */
  }
}
